<template>
  <div>
    <div class="header" @click="toggleContent">
      <slot name="header"></slot>
    </div>
    <slot v-if="contentVisible"></slot>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
export default {
  name: "Collapsible",
  props: { header: String, key: String },
  emits: ["toggled"],
  setup(props, { emit }) {
    const contentVisible = ref(false);

    const toggleContent = (e) => {
      contentVisible.value = !contentVisible.value;
      emit("toggled", e);
    };

    return {
      contentVisible,
      toggleContent,
    };
  },
};
</script>
